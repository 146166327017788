import { novuInAppConfig } from '../credentials';
import { InAppProviderIdEnum } from '../provider.enum';
import { ChannelTypeEnum } from '../../../types';
export const inAppProviders = [
    {
        id: InAppProviderIdEnum.Novu,
        displayName: 'Notification Center',
        channel: ChannelTypeEnum.IN_APP,
        credentials: novuInAppConfig,
        docReference: 'https://docs.novu.co/notification-center/getting-started',
        logoFileName: { light: 'novu.png', dark: 'novu.png' },
    },
];
