import { gupshupConfig, nexmoConfig, plivoConfig, sms77Config, snsConfig, telnyxConfig, twilioConfig, firetextConfig, infobipSMSConfig, burstSmsConfig, clickatellConfig, fortySixElksConfig, kannelConfig, maqsamConfig, smsCentralConfig, termiiConfig, africasTalkingConfig, } from '../credentials';
import { SmsProviderIdEnum } from '../provider.enum';
import { ChannelTypeEnum } from '../../../types';
export const smsProviders = [
    {
        id: SmsProviderIdEnum.Nexmo,
        displayName: 'Nexmo',
        channel: ChannelTypeEnum.SMS,
        credentials: nexmoConfig,
        docReference: 'https://docs.novu.co/channels/sms/nexmo',
        logoFileName: { light: 'nexmo.png', dark: 'nexmo.png' },
    },
    {
        id: SmsProviderIdEnum.Plivo,
        displayName: 'Plivo',
        channel: ChannelTypeEnum.SMS,
        credentials: plivoConfig,
        docReference: 'https://www.plivo.com/docs/',
        logoFileName: { light: 'plivo.png', dark: 'plivo.png' },
    },
    {
        id: SmsProviderIdEnum.Sms77,
        displayName: 'sms77',
        channel: ChannelTypeEnum.SMS,
        credentials: sms77Config,
        docReference: 'https://docs.novu.co/channels/sms/SMS77',
        logoFileName: { light: 'sms77.svg', dark: 'sms77.svg' },
    },
    {
        id: SmsProviderIdEnum.SNS,
        displayName: 'SNS',
        channel: ChannelTypeEnum.SMS,
        credentials: snsConfig,
        docReference: 'https://docs.novu.co/channels/sms/sns',
        logoFileName: { light: 'sns.svg', dark: 'sns.svg' },
    },
    {
        id: SmsProviderIdEnum.Telnyx,
        displayName: 'Telnyx',
        channel: ChannelTypeEnum.SMS,
        credentials: telnyxConfig,
        docReference: 'https://docs.novu.co/channels/sms/telnyx',
        logoFileName: { light: 'telnyx.png', dark: 'telnyx.png' },
    },
    {
        id: SmsProviderIdEnum.Twilio,
        displayName: 'Twilio',
        channel: ChannelTypeEnum.SMS,
        credentials: twilioConfig,
        docReference: 'https://docs.novu.co/channels/sms/twilio',
        logoFileName: { light: 'twilio.png', dark: 'twilio.png' },
    },
    {
        id: SmsProviderIdEnum.Gupshup,
        displayName: 'Gupshup',
        channel: ChannelTypeEnum.SMS,
        credentials: gupshupConfig,
        docReference: 'https://docs.gupshup.io/docs/send-single-message',
        logoFileName: { light: 'gupshup.png', dark: 'gupshup.png' },
    },
    {
        id: SmsProviderIdEnum.Firetext,
        displayName: 'Firetext',
        channel: ChannelTypeEnum.SMS,
        credentials: firetextConfig,
        docReference: 'https://www.firetext.co.uk/docs',
        logoFileName: { light: 'firetext.svg', dark: 'firetext.svg' },
    },
    {
        id: SmsProviderIdEnum.Infobip,
        displayName: 'Infobip',
        channel: ChannelTypeEnum.SMS,
        credentials: infobipSMSConfig,
        docReference: 'https://www.infobip.com/docs',
        logoFileName: { light: 'infobip.png', dark: 'infobip.png' },
    },
    {
        id: SmsProviderIdEnum.BurstSms,
        displayName: 'BurstSMS',
        channel: ChannelTypeEnum.SMS,
        credentials: burstSmsConfig,
        docReference: 'https://developer.transmitsms.com/',
        logoFileName: { light: 'burst-sms.svg', dark: 'burst-sms.svg' },
    },
    {
        id: SmsProviderIdEnum.Clickatell,
        displayName: 'clickatell',
        channel: ChannelTypeEnum.SMS,
        credentials: clickatellConfig,
        betaVersion: true,
        docReference: 'https://docs.clickatell.com/',
        logoFileName: { light: 'clickatell.png', dark: 'clickatell.png' },
    },
    {
        id: SmsProviderIdEnum.FortySixElks,
        displayName: '46elks',
        channel: ChannelTypeEnum.SMS,
        credentials: fortySixElksConfig,
        docReference: 'https://46elks.com/docs/send-sms',
        logoFileName: { light: '46elks.png', dark: '46elks.png' },
    },
    {
        id: SmsProviderIdEnum.Kannel,
        displayName: 'Kannel SMS',
        channel: ChannelTypeEnum.SMS,
        credentials: kannelConfig,
        betaVersion: true,
        docReference: 'https://www.kannel.org/doc.shtml',
        logoFileName: { light: 'kannel.png', dark: 'kannel.png' },
    },
    {
        id: SmsProviderIdEnum.Maqsam,
        displayName: 'Maqsam',
        channel: ChannelTypeEnum.SMS,
        credentials: maqsamConfig,
        docReference: 'https://portal.maqsam.com/docs/v2/sms',
        logoFileName: { light: 'maqsam.png', dark: 'maqsam.png' },
    },
    {
        id: SmsProviderIdEnum.SmsCentral,
        displayName: 'SMS Central',
        channel: ChannelTypeEnum.SMS,
        credentials: smsCentralConfig,
        docReference: 'https://www.smscentral.com.au/sms-api/',
        logoFileName: { light: 'sms-central.png', dark: 'sms-central.png' },
    },
    {
        id: SmsProviderIdEnum.Termii,
        displayName: 'Termii',
        channel: ChannelTypeEnum.SMS,
        credentials: termiiConfig,
        docReference: 'https://developers.termii.com/authentication',
        logoFileName: { light: 'termii.png', dark: 'termii.png' },
    },
    {
        id: SmsProviderIdEnum.AfricasTalking,
        displayName: `Africa's Talking`,
        channel: ChannelTypeEnum.SMS,
        credentials: africasTalkingConfig,
        docReference: 'https://docs.novu.co/channels/sms/africas-talking',
        logoFileName: { light: 'africas-talking.svg', dark: 'africas-talking.svg' },
    },
];
