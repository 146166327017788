export const TemplateSystemVariables = ['subscriber', 'step', 'branding', 'preheader'];
export const SystemVariablesWithTypes = {
    subscriber: {
        firstName: 'string',
        lastName: 'string',
        email: 'string',
        phone: 'string',
        avatar: 'string',
        locale: 'string',
        subscriberId: 'string',
    },
    step: {
        digest: 'boolean',
        events: 'array',
        total_count: 'number',
    },
    branding: {
        logo: 'string',
        color: 'string',
    },
};
